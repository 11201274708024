import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";

import Home from "./pages/Home";
import { getLibrary } from "./components/Layout/NavBar";

import { useLocation } from 'react-router-dom';
import { analytics } from './firebase/firebase';
import { logEvent } from "firebase/analytics";

const queryClient = new QueryClient();

function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
    });
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    logEvent(analytics, 'app_start');
  }, []);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <TrackPageViews /> {/* Place TrackPageViews inside BrowserRouter */}
          <div className="w-screen min-h-screen bg-background">
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          </div>
        </BrowserRouter>
        <Toaster position="top-right" />
      </QueryClientProvider>
    </Web3ReactProvider>
  );
}

export default App;
