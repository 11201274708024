import React from "react";
import Container from "../Container";
import Heading from "../Typography/Heading";
import Text from "../Typography/Text";

export default function Benefits() {
  return (
    <Container
      id="BENEFITS"
      className="about flex flex-col items-center gap-8 lg:flex-row relative mt-20" 
    >
      {/* Image Container for large screens */}
      <div className="hidden lg:flex lg:w-1/2 justify-center items-center">
        <div className="box2">
          <img 
            src="/assets/images/benefits/why-us2.jpg" 
            alt="Why Us" 
            className="rounded-lg w-full h-auto" 
          />
        </div>
      </div>

      {/* Text Container */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center">
        <p className="txt">Benefits</p>
        <Heading className="text-7xl leading-tight !text-left">
          Why Choose dOpus?<br />
        </Heading>
        <div className="space-y-4 mt-8 text-center lg:text-left">
          <Text>
            <strong>Passive Income Generation: </strong>
            Turn your everyday spaces into passive income streams. With dOpus, you can earn money simply by displaying ads in areas you already own and use.
          </Text>
          <Text>
            <strong>Decentralized and Transparent:  </strong>
            Built on blockchain technology, dOpus ensures all transactions are secure, transparent, and immutable. Both advertisers and property owners benefit from a trustless environment where all terms are guaranteed.
          </Text>
          <Text>
            <strong>Empowering Individuals:  </strong>
            dOpus shifts the power of advertising to the individual. No longer are large billboards or costly campaigns the only way to reach an audience. Everyday people can now participate in the advertising economy and profit from it.
          </Text>
        </div>
      </div>

      {/* Image Container for small screens */}
      <div className="flex lg:hidden justify-center items-center w-full">
        <div className="box2">
          <img 
            src="/assets/images/benefits/why-us.jpg" 
            alt="Why Us" 
            className="rounded-lg w-full h-auto" 
          />
        </div>
      </div>
    </Container>
  );
}
