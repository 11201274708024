import Container from "../Container";
import Heading from "../Typography/Heading";
import Text from "../Typography/Text";

export default function Introduction() {
  return (
    <Container
      id="INTRODUCTION"
      className="intro flex flex-col items-center gap-8 lg:flex-row relative"
    >
      <div className="w-[800px] h-[800px] bg-[#4E34EE]/10 filter blur-3xl absolute top-0 left-0 rounded-full transform -translate-x-2/3 -translate-y-20" />

      {/* Parent container with flexbox to vertically center text */}
      <div className="flex w-full lg:w-1/2 lg:flex lg:items-center">
        <div className="w-full">
          <Heading className="text-7xl leading-tight !text-left">
            Decentralized Advertising for Everyday Spaces <br />
          </Heading>

          <div className="space-y-4 text-center lg:text-left pt-5">
            <Text>
              Turn your car, home, or any owned space into a revenue-generating ad platform.
              Connect with brands, display ads on your terms, and earn rewards effortlessly.
              With proof of placement and transparent contracts, dOpus makes monetizing your everyday spaces simple and secure.
            </Text>

            <Text>
              dOpus functions as the Airbnb or Uber of advertising, creating a marketplace where property owners and advertisers can collaborate.
              Whether it’s placing a sticker on your car, a banner on your home, or utilizing any other personal space for advertising,
              dOpus facilitates these partnerships through a seamless platform.
              Property owners set the terms, including the type of advertisement, duration, and reward, while advertisers find the perfect location that aligns
              with their target audience.
            </Text>
          </div>
        </div>
      </div>

      {/* Image container */}
      <div className="lg:w-1/2 flex justify-center items-center">
        <div className="box2">
          <img 
            src="/assets/images/intro/car2.jpg" 
            alt="Car" 
            className="rounded-lg" 
          />
        </div>
      </div>
    </Container>
  );
}
